import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Aquest xampinyó posseeix un capell de 5 a 8 cm de diàmetre, hemisfèric al principi passant a pla i lleugerament deprimit al centre. La cutícula està dissociada en petites esquames molt juntes en forma de metxes de color que van del color caramel al marró un poc grisenc i un poc violaci. Per davall aquest, les làmines són lliures, estretes, de color rosa salmó a negrós. El peu és ample, amb petites esquames de color un poc rogenc que es troben per sobre de l’anell, per sota del qual és de color blanc. L’anell és blanquinós, ínfer i abraça el peu, esfilagarsat cap al marge. Les espores són negroses en massa, quasi rodones, de 5-6 x 4-5 micres.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      